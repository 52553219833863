<template>
  <registry-table
    v-show="!isHidden"
    :registry-id="registryId"
    :custom-columns="columns"
    :external-filters="dataFilters"
  >
    <slot></slot>
  </registry-table>
</template>

<script>
import mixin from '@/components/InterfaceEditor/components/mixins'
import VisibleMixin from '@/components/InterfaceEditor/components/visible_properties_mixin'
import RegistryTable from '@/services/RegistryTable'

export default {
  name: 'registry',
  components: { RegistryTable },
  mixins: [mixin, VisibleMixin],
  inject: ['openRegistryCard', 'getModel'],
  props: {
    registryId: {
      description: 'Выберите реестр',
      type: Number,
      editor: 'RegistrySelect'
    },
    columns: {
      description: 'Столбцы',
      type: Array,
      default: () => [],
      editor: 'TableColumns',
      options: {
        sourceType: 'registry'
      }
    },
    filters: {
      type: Array,
      editor: 'Filters',
      options: {
        showXrefOption: true
      }
    }
  },
  computed: {
    dataFilters () {
      let filters = []
      if (this.filters) {
        this.filters.forEach((item) => {
          let object = {}
          let equalsType = 'eq'
          if (item.isXref) {
            equalsType = 'equals_any'
          }
          object[equalsType] = {}
          if (!item.alias) {
            return
          }
          let alias = item.isXref ? `${item.alias}id` : item.isXref
          if (!item.type || item.type === 'field') {
            if (this.getModel()[item.attribute]) {
              object[equalsType][alias] = this.getModel()[item.attribute]
            }
          } else if (item.type === 'constant') {
            object[equalsType][alias] = item.attribute
          } else if (item.type === 'current_user') {
            object[equalsType][alias] = this.$store.getters['Authorization/userId']
          }
          if (Object.keys(object[equalsType]).length > 0) {
            filters.push(object)
          }
        })
      }
      return filters
    }
  }
}
</script>

<style scoped>

</style>
