<template>
  <div class="toolbar">
    <i class="el-icon-refresh-right" @click="emit('load')"></i>
    <i class="el-icon-plus" @click="emit('add')"></i>
    <i class="el-icon-edit" @click="emit('edit')"></i>
    <i class="el-icon-delete"
       style="color: #FD4644"
       @click="emit('delete')"
    ></i>
  </div>
</template>

<script>
export default {
  name: 'toolbar',
  inject: ['getEventBus'],
  methods: {
    emit (type) {
      this.getEventBus().$emit(type)
    }
  }
}
</script>

<style scoped src="./Toolbar.css">

</style>
