<template>
  <grid
    ref="grid"
    :columns="resultColumns"
    :data-source-service="dataSourceService"
    :pagination="true"
    :page-size="100"
    :multi-selection="true"
    :check-box-selection="true"
  ></grid>
</template>

<script>
import Grid from '@/core/infrastructure/components/Grid/index'
import StructureQuery from '@/services/RegistryTable/application/query/StructureQuery'
import { ColumnsFactory } from '@/services/RegistryTable/domain/service/ColumnsFactory'
import { DataSource } from '@/services/RegistryTable/infrastructure/service/DataSource'
export default {
  name: 'table-body',
  components: {
    Grid
  },
  props: {
    registryId: {
      type: Number
    },
    customColumns: {
      type: Array
    },
    externalFilters: {
      type: Array
    }
  },
  inject: ['getQueryBus', 'getEventBus'],
  data () {
    return {
      columns: [],
      dataSourceService: undefined,
      filterDebounce: undefined
    }
  },
  beforeMount () {
    this.dataSourceService = new DataSource(this.registryId, this.getQueryBus(), this.externalFilters)
    this.loadStructure()
  },
  mounted () {
    this.registerEvents()
  },
  watch: {
    externalFilters () {
      if (this.filterDebounce) {
        clearTimeout(this.filterDebounce)
      }
      this.filterDebounce = setTimeout(() => {
        this.dataSourceService.setExternalFilters(this.externalFilters)
        this.$refs.grid.load()
      }, 300)
    }
  },
  methods: {
    registerEvents () {
      this.getEventBus().$on('load', () => {
        this.$refs.grid.load()
      })
    },
    async loadStructure () {
      if (typeof this.customColumns === 'undefined') {
        const { columns } = await this.getQueryBus().execute(new StructureQuery(this.registryId))
        this.columns = ColumnsFactory.build(columns)
      }
    }
  },
  computed: {
    resultColumns () {
      return typeof this.customColumns === 'undefined' ? this.columns : this.customColumns
    }
  }
}
</script>

<style scoped>

</style>
