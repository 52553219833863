<template>
  <div class="registry">
    <toolbar class="toolbar-box"></toolbar>
    <table-body
      class="table-box"
      :key="registryId"
      :registry-id="registryId"
      :custom-columns="customColumns"
      :external-filters="externalFilters"
    ></table-body>
    <div class="footer-box"></div>
    <slot></slot>
  </div>
</template>

<script>
import QueryBus from '@/core/application/query/service/QueryBus'
import QueryBusHandlerLocator from '@/core/application/query/service/QueryBusHandlerLocator'
import StructureHandler from '@/services/RegistryTable/application/handler/query/StructureHandler'
import StructureRepository from '@/services/RegistryTable/infrastructure/domain/repository/StructureRepository'
import TableBody from '@/services/RegistryTable/infrastructure/components/TableBody/index'
import Vue from 'vue'
import DataHandler from '@/services/RegistryTable/application/handler/query/DataHandler'
import DataRepository from '@/services/RegistryTable/infrastructure/domain/repository/DataRepository'
import Toolbar from '@/services/RegistryTable/infrastructure/components/Toolbar/index'
export default {
  name: 'registry-table',
  props: {
    registryId: {
      type: Number
    },
    customColumns: {
      type: Array
    },
    externalFilters: {
      type: Array
    }
  },
  components: {
    TableBody,
    Toolbar
  },
  data () {
    return {
      eventBus: new Vue(),
      queryBus: new QueryBus(
        new QueryBusHandlerLocator({
          'StructureQuery': new StructureHandler(
            new StructureRepository()
          ),
          'DataQuery': new DataHandler(
            new DataRepository()
          )
        })
      )
    }
  },
  provide () {
    return {
      getEventBus: this.getEventBus,
      getQueryBus: this.getQueryBus
    }
  },
  methods: {
    getEventBus () {
      return this.eventBus
    },
    getQueryBus () {
      return this.queryBus
    }
  }
}
</script>

<style scoped src="./RegistryTable.css">

</style>
