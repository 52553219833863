import QueryBus from '@/core/application/query/service/QueryBus'
import DataQuery from '@/services/RegistryTable/application/query/DataQuery'
import {
  IServerSideDatasource,
  IServerSideGetRowsParams
} from 'ag-grid-community/dist/lib/interfaces/iServerSideDatasource'
import { RequestBuilder } from '@/core/infrastructure/components/Grid/infrastructure/service/RequestBuilder'
import PivotColumnsBuilder from '@/core/infrastructure/components/Grid/infrastructure/service/PivotColumnsBuilder'
import { Nullable } from '@/core/domain/type/types'

export class DataSource implements IServerSideDatasource {
  private queryBus: QueryBus;
  private readonly registryId: number;
  private externalFilters: Nullable<object[]>;
  constructor (registryId: number, queryBus: QueryBus, externalFilters: Nullable<object[]> = null) {
    this.registryId = registryId
    this.queryBus = queryBus
    this.externalFilters = externalFilters
  }

  setExternalFilters (externalFilters: Nullable<object[]>) {
    this.externalFilters = externalFilters
  }

  getRows (params: IServerSideGetRowsParams) {
    const pivotColumnIsXref = params.request.pivotCols.length > 0
      ? params.columnApi.getColumn(params.request.pivotCols[0].id).getUserProvidedColDef().type.includes('xrefField')
      : false
    const payload = RequestBuilder.build(params.request, pivotColumnIsXref, this.externalFilters)

    this.queryBus.execute(new DataQuery(this.registryId, payload)).then((response) => {
      const { data, count, pivot_fields } = response
      if (params.request.groupKeys.length === 0) {
        PivotColumnsBuilder.build(params.request, pivot_fields, params.columnApi)
      }
      params.successCallback(data, count)
    }).catch((error) => {
      params.failCallback()
      throw error
    })
  }
}
